import imagem from './img/inicio_fundo.png'
function App() {
  return (
    <div >
     
     <div style={{textAlign: 'center'}}>
      <img src={imagem} alt="logo" style={{width: '300px'}}/>
     </div>

      <div style={{textAlign: 'center'}}>
      
        <h1>Zamo Sistemas</h1>
        <h4>Soluções Web</h4>
      </div>
    </div>
  );
}

export default App;
